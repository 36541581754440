.root {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;

  @media (min-width: 760px) {
    margin-bottom: 1rem;
  }

  .select {
    margin-top: 8px;
  }
}

.label {
  font-size: 0.875rem;
  font-weight: 700;
  color: hsl(240 10% 3.9%);
}

.select {
  font-size: 0.875rem;
  line-height: 1.25rem;
  outline: none;

  :global{
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .MuiSelect-select {
      border: 1px solid hsl(240 5.9% 90%);
      border-radius: 0.375rem;

      &:focus {
        outline: 2px solid hsl(240 5% 64.8%);
        outline-offset: 2px;
      }
    }

    .MuiSelect-nativeInput {
      padding: 8px 12px;
    }
  }

}