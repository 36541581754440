.root {
  border-collapse: collapse;

  thead {
    border-bottom: 1px solid lightgray;
  }

  tbody {
    > tr + tr {
      border-top: 1px solid lightgray;
    }
  }

  tr {
    &:hover {
      background-color: hsl(240 4.8% 95.9% / 0.5);
    }
  }

  th,
  td {
    font-size: 0.875rem;
    padding: 8px;

    @media (min-width: 760px) {
      padding: 16px;
    }
  }

  th {
    color: hsl(240 3.8% 46.1%);
    white-space: nowrap;
  }

  th,
  td {
    text-align: right;
  }

  th:first-child,
  td:first-child {
    white-space: nowrap;
    text-align: left;
  }
}