.root {
  display: flex;
  flex-direction: column;

  .input {
    margin-top: 8px;
  }
}

.label {
  font-size: 0.875rem;
  font-weight: 700;
  color: hsl(240 10% 3.9%);
}

.input {
  border: 1px solid hsl(240 5.9% 90%);
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 8px 12px;

  &:focus {
    outline: 2px solid hsl(240 5% 64.8%);
    outline-offset: 2px;
  }
}