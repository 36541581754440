.root {
  padding: 20px 0;

  @media (min-width: 760px) {
    padding: 48px 0;
  }

  .resultCard {
    margin-top: 24px;
  }
}

.formCard {
  border: 1px solid #e5e7eb;
  border-radius: calc(0.5rem - 2px);
  padding: 24px;

  .form {
    margin-top: 24px;

    @media (min-width: 760px) {
      margin-top: 48px;
    }
  }
}

.header {
  .title {
    margin: 0;
  }

  .description {
    margin: 6px 0 0 0;
  }
}

.title {
  font-size: 1.5rem;
}

.description {
  padding: 0;
  font-size: 0.875rem;
}

.form {
  /*! keep */
}

.resultCard {
  overflow: hidden;

  .resultCardBody {
    margin-top: 30px;
  }
}

.resultCardTitle {
  font-size: 1.3rem;

  > small {
    display: block;
    font-weight: 500;
    margin-top: 4px;

    > strong {
      padding: 0 4px;
      background-color: #e5e7eb;
    }
  }
}

.resultCardBody {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-x: scroll;

  @media (min-width: 1200px) {
    flex-direction: row-reverse;
  }

  .chart,
  .table {
    flex: 1;
  }
}

.summaryTable {
  margin: 0 auto 50px auto;
}