.root {
  .action {
    margin-top: 20px;

    @media (min-width: 760px) {
      margin-top: 30px;
    }
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: 760px) {
    justify-content: center;
  }
}

.body {
  > * + * {
    margin-top: 1rem;
  }

  > * + .error {
    margin-top: 4px;
  }
}

.error {
  color: red;
}